module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"flaik","short_name":"flaik","start_url":"/en","background_color":"#14181c","theme_color":"#14181c","display":"standalone","icons":[{"src":"/favicons/android-chrome-36x36.png","sizes":"36x36","type":"image/png"},{"src":"/favicons/android-chrome-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/android-chrome-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/android-chrome-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/favicons/android-chrome-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-256x256.png","sizes":"256x256","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-EBD51L60TH"],"pluginConfig":{"head":true,"respectDNT":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
