import * as React from "react";
import { func, string, number, oneOf, oneOfType, node } from "prop-types";

import { NULL_OP } from "Services/constants/empty";

export const ErrorBoundaryTypes = {
	APP: "APP",
	PAGE: "PAGE",
	ICON: "ICON",
};

class ErrorBoundary extends React.Component {
	static propTypes = {
		errorRenderer: func,
		identifier: oneOfType([string, number]),
		children: node,
		type: oneOf(["APP", "PAGE", "ICON"]),
	};
	static defaultProps = {
		errorRenderer: NULL_OP,
		identifier: "app",
		type: ErrorBoundaryTypes.APP,
	};
	static getDerivedStateFromProps(nextProps, prevState) {
		if (prevState.identifier !== nextProps.identifier) {
			return {
				hasError: false,
				identifier: nextProps.identifier,
			};
		}
		return null;
	}
	constructor(props) {
		super(props);
		this.state = { hasError: false, identifier: props.identifier };
	}
	componentDidCatch(error, info) {
		const { type } = this.props;
		// Display fallback UI
		this.setState({ hasError: true });
		if (typeof window !== "undefined" && window.ga) {
			window.ga(`send`, `event`, {
				eventCategory: `ErrorBoundary hit`,
				eventAction: `error`,
				eventLabel: type,
				error,
				info,
			});
		}
	}
	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return this.props.errorRenderer;
		}
		return this.props.children;
	}
}

export default ErrorBoundary;
