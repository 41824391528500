// empty basics
export const EMPTY_ARR = Object.freeze([]);
export const EMPTY_OBJ = Object.freeze({});

export const EMPTY_PROMISE = () =>
	new Promise((resolve) => {
		if (typeof window === "undefined") {
			resolve();
		}
		window.setTimeout(() => {
			resolve();
		});
	});
export const EMPTY_UNRESOLVED_PROMISE = () => new Promise((resolve) => {});

// empty functions
export const NOOP = (firstArg, ...args) => firstArg;
export const FALSE_OP = (...args) => false;
export const NULL_OP = (...args) => null;
export const TRUE_OP = (...args) => true;
