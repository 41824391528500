import { createStore as reduxCreateStore } from "redux";

import { EMPTY_OBJ, EMPTY_ARR } from "Services/constants/empty";

const reducer = (state, action) => {
	if (action.type === `MODAL_KEY_SET`) {
		return Object.assign({}, state, {
			modalKey: action.payload.modalKey || null,
			modalProps: action.payload.modalProps || EMPTY_OBJ,
		});
	} else if (action.type === `FORM_SUBMIT_RESET`) {
		return Object.assign({}, state, {
			completedForms: state.completedForms.filter(
				(key) => key !== action.payload.formKey
			),
			formResponse: {
				key: "",
				response: EMPTY_OBJ,
			},
		});
	} else if (action.type === `FORM_SUBMIT_RESPONSE`) {
		if (action.payload.response && action.payload.response.code === 200) {
			return Object.assign({}, state, {
				completedForms: state.completedForms.concat(action.payload.formKey),
				formResponse: {
					key: action.payload.formKey,
					response: action.payload.response,
				},
			});
		}
		return Object.assign({}, state, {
			formResponse: {
				key: action.payload.formKey,
				response: action.payload.response,
			},
		});
	}
	return state;
};

const initialState = {
	modalKey: null,
	modalProps: EMPTY_OBJ,
	completedForms: EMPTY_ARR,
	formResponse: {
		key: "",
		response: EMPTY_OBJ,
	},
};

const createStore = () => reduxCreateStore(reducer, initialState);
export default createStore;
